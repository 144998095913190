<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item label="热搜词标题" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入热搜词标题"/>
          </a-form-item>

          <a-form-item label="是否滚动展示" name="isRound" class="ui-form__item">
            <a-select v-model:value="formState.isRound" placeholder="请选择是否滚动展示" allow-clear style="width: 190px;">
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 190px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['mall_hotSearch_add']" type="primary" @click="onAdd">新增热搜词</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isRound'">
              {{ record.isRound ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['mall_hotSearch_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_hotSearch_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_hotSearch_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['mall_hotSearch_del']" @click="onDelete(record)">
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showModal" destroyOnClose :title="modelRef.id ? '修改热搜词' : '添加热搜词'" width="750px" @ok="onSubmit">
        <a-spin :spinning="loading">
          <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
            <a-form-item label="热搜词标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input :maxLength="6" v-model:value="modelRef.title" placeholder="请输入热搜词标题"/>
            </a-form-item>

            <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
              <a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.sort" placeholder="请输入优先级"/>
            </a-form-item>

            <a-form-item label="是否滚动展示" name="isRound">
              <a-switch v-model:checked="modelRef.isRound" checked-children="是" un-checked-children="否" />
            </a-form-item>

            <a-form-item label="是否启用" name="isDisabled">
              <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import {
  deletePlatformHotSearch,
  getPlatformHotSearchList, savePlatformHotSearch,
  updatePlatformHotSearch
} from "../../../service/modules/mall";
export default {
  components: {Icon },
  data() {
    return {
      loading: false,
      formState: {},
      searchData: {},
      showModal: false,
      modelRef: {},
      list: [],
      columns: [{
        title: '热搜词标题',
        dataIndex: 'title',
        width: 200
      }, {
        title: '是否滚动展示',
        key: 'isRound',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        width: 150
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getPlatformHotSearchList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        });
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.list = ret.data.list;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onAdd() {
      this.isEdit = false;
      this.showModal = true;
      this.modelRef = {};
      this.modelRef.isDisabled = true;
      this.modelRef.isRound = false;
    },
    onEdit(item) {
      this.isEdit = true;
      this.id = item.id;
      this.modelRef = JSON.parse(JSON.stringify(item));
      this.modelRef.isDisabled = this.modelRef.isDisabled ? false : true;
      this.modelRef.isRound = this.modelRef.isRound ? true : false;
      this.showModal = true;
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确定删除该热搜词吗？',
        onOk: async ()=> {
          this.loading = true;
          try {
            let ret = await deletePlatformHotSearch({
              id: item.id
            });
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    async onSubmit() {
      this.$refs.addForm.validateFields().then(async () => {
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        postData.isRound = postData.isRound ? 1 : 0;
        this.loading = true;
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            ret = await updatePlatformHotSearch(postData);
          } else {
            ret = await savePlatformHotSearch(postData);
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.getData();
            this.showModal = false;
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该热搜词吗',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updatePlatformHotSearch({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            });
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' :'禁用') + '成功！');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
